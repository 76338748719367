import * as React from "react"
import { Link } from "gatsby"
import Layout from "../containers/Layout";


const NotFoundPage = () => {
  return (
    <Layout>
      404 Not Found
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
